import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Section, Divider } from '@class101/ui';
import { Playground, Props } from 'docz';
import { StyledDivider } from '../docsComponents';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "divider"
    }}>{`Divider`}</h1>
    <p>{`두 섹션을 나눌 수 있는 Divider입니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Divider} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <p>{`섹션 사이에 Divider를 넣어서 사용할 수 있습니다.`}</p>
    <p>{`기본적으로 margin이나 padding이 적용되어 있지 않습니다.`}</p>
    <Playground __position={1} __code={'<Section title=\"Section1\">Content1</Section>\n<Divider />\n<Section title=\"Section2\">Content2</Section>'} __scope={{
      props,
      DefaultLayout,
      Section,
      Divider,
      Playground,
      Props,
      StyledDivider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Section title="Section1" mdxType="Section">Content1</Section>
  <Divider mdxType="Divider" />
  <Section title="Section2" mdxType="Section">Content2</Section>
    </Playground>
    <h2 {...{
      "id": "styled-divider"
    }}>{`Styled Divider`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export const StyledDivider = styled(Divider)\`
  padding: 12px 0;
\`;
`}</code></pre>
    <Playground __position={2} __code={'<Section title=\"Section1\">Content1</Section>\n<StyledDivider color=\"red\" />\n<Section title=\"Section2\">Content2</Section>'} __scope={{
      props,
      DefaultLayout,
      Section,
      Divider,
      Playground,
      Props,
      StyledDivider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Section title="Section1" mdxType="Section">Content1</Section>
  <StyledDivider color="red" mdxType="StyledDivider" />
  <Section title="Section2" mdxType="Section">Content2</Section>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      